<!--
 * @Description: 新增、修改菜单、api数据
 * @Author: 琢磨先生
 * @Date: 2022-05-08 17:57:12
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-22 14:53:50
-->

<template>
  <el-dialog
    v-model="drawer"
    :title="title"
    width="800px"
    top="10vh"
    :append-to-body="true"
    :close-on-click-modal="false"
    draggable
    @closed="onClosed"
  >
    <el-form ref="form" :model="form" label-width="140px" :rules="rules">
      <el-form-item label="应用" prop="app_key">
        <el-select
          v-model="form.app_key"
          placeholder="请选择应用"
          @change="appChange"
        >
          <el-option
            v-for="item in apps"
            :key="item.app_key"
            :label="item.name"
            :value="item.app_key"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="上级" prop="fatherIds">
        <el-cascader
          clearable
          v-model="form.fatherIds"
          :options="options"
          :props="fatherProps"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-col :span="10">
          <el-input
            v-model="form.name"
            placeholder="请输入名称"
            clearable
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="唯一编码" prop="code">
        <el-col :span="15">
          <el-input v-model="form.code" placeholder="" clearable></el-input>
          <div class="help-block">格式支持字母和_组合</div>
        </el-col>
      </el-form-item>
      <el-form-item
        label="url地址"
        prop="url"
        v-if="current_app.platform === 0"
      >
        <el-col :span="18">
          <el-input v-model="form.url" placeholder="" clearable></el-input>
          <div class="help-block">vue url地址、api访问地址</div>
        </el-col>
      </el-form-item>
      <el-form-item
        label="redirect"
        prop="redirect"
        v-if="current_app.platform === 0 && is_menu"
      >
        <el-col :span="18">
          <el-input v-model="form.redirect" placeholder="" clearable></el-input>
        </el-col>
      </el-form-item>
      <el-form-item
        label="页面路径"
        prop="component"
        v-if="current_app.platform === 0 && is_menu"
      >
        <el-col :span="18">
          <el-input
            v-model="form.component"
            placeholder=""
            clearable
          ></el-input>
          <div class="help-block">
            vue顶级填写:layout，存在第三级时第二级填写:layout2，小程序填写 page
            路径
          </div>
        </el-col>
      </el-form-item>

      <el-form-item
        label="vue icon"
        prop="icon_class"
        v-if="current_app.platform === 0 && is_menu"
      >
        <el-col :span="10">
          <el-input
            v-model="form.icon_class"
            placeholder=""
            clearable
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="排序(正序)" prop="sequence">
        <el-col :span="10">
          <el-input
            type="number"
            v-model="form.sequence"
            placeholder=""
            clearable
          ></el-input>
        </el-col>
      </el-form-item>
      
      <el-form-item label="停用">
        <el-switch v-model="form.is_stop" />
      </el-form-item>
 
    </el-form>
    <template #footer>
      <el-button
        type="primary"
        @click="submitForm(ruleFormRef)"
        :loading="loading"
        >确定
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import function_api from "@/http/function_api";

export default {
  data() {
    return {
      //提交加载中
      loading: false,
      title: "",
      drawer: false,
      form: {
        name: "",
      },
      //当前选择的app
      current_app: {},
      //用于父级选择
      options: [],
      //菜单数据
      functions: [],
      rules: {
        name: [{ required: true, message: "名称必填", trigger: "blur" }],
        code: [{ required: true, message: "唯一编码必填", trigger: "blur" }],
        app_key: [
          {
            required: true,
            message: "应用必选",
            trigger: "blur",
          },
        ],
      },
      fatherProps: {
        checkStrictly: true,
      },
    };
  },
  props: ["item", "apps", "is_menu"],
  emits: ["close", "success"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.drawer = true;
          this.form = Object.assign({}, this.item);
          if (this.item) {
            this.loadData();
            if (this.item.app_key) {
              var app = this.apps.find((o) => o.app_key == this.item.app_key);
              this.current_app = app;
            }
            if (this.item.id) {
              this.title = `修改${this.is_menu ? "菜单" : "Api接口"}`;
            } else {
              this.title = `新增${this.is_menu ? "菜单" : "Api接口"}`;
            }
          }
        }
      },
    },
    is_menu: {
      handler() {
        // if (this.is_menu) {
        //   if (this.item && this.item.id) {
        //     this.title = "修改菜单";
        //   } else {
        //     this.title = "新增菜单";
        //   }
        // } else {
        //   if (this.item && this.item.id) {
        //     this.title = "修改Api接口";
        //   } else {
        //     this.title = "新增Api接口";
        //   }
        // }
      },
      immediate: true,
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 选择应用
     * @param {*} e
     */
    appChange(e) {
      var app = this.apps.find((o) => o.app_key == e);
      this.current_app = app;
      this.loadData();
    },

    /**
     * 递归父级选择数据
     */
    recursion(item) {
      var list = this.functions.filter((x) => x.father_id == item.value);
      if (list.length > 0) {
        list.map((x) => {
          if (this.item) {
            if (x.id != this.item.id) {
              var node = {
                label: x.name,
                value: x.id,
                children: [],
              };
              item.children.push(node);
              this.recursion(node);
            }
          }
        });
      }
    },
    /**
     * 取消
     */
    cancelClick() {
      this.drawer = false;
      if (this.reload) {
        this.reload();
      }
    },

    /**
     * 加载菜单数据
     */
    loadData() {
      function_api.get_funs(this.form.app_key).then((res) => {
        if (res.code == 0) {
          this.functions = res.data.filter((x) => x.is_menu == this.is_menu);
          this.handlerFunction();
        }
        this.loading = false;
      });
    },

    /**
     *
     */
    handlerFunction() {
      var datas = [];
      if (this.item) {
        datas = this.functions.filter((x) => x.id != this.item.id);
      }
      var ops = [];
      datas
        .filter((x) => x.father_id == null)
        .map((x) => {
          var item = {
            label: x.name,
            code: x.code,
            value: x.id,
            children: [],
          };
          ops.push(item);
          this.recursion(item);
        });
      this.options = ops;
    },

    /**
     *
     */
    onClosed() {
      this.$emit("close");
    },

    /**
     * 表单提交
     */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.disabled = true;
          this.form.is_menu = this.is_menu;
          this.form.father_id =
            this.form.fatherIds && this.form.fatherIds.length > 0
              ? this.form.fatherIds[this.form.fatherIds.length - 1]
              : null;
          this.$http
            .post("/admin/v1/function/edit", this.form)
            .then((res) => {
              this.loading = false;
              if (res.code == 0) {
                this.drawer = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$emit("success");
              }
            })
            .cath(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
