/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2022-05-08 16:53:52
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-05 17:05:16
 */
import http from "./axios_init";

export default {
  /**
   * 获取应用的菜单、api数据
   * @param {*} appkey
   * @returns
   */
  get_funs(appkey, isMenu) {
    return http.get(
      `/admin/v1/function?appkey=${appkey}&is_menu=${isMenu ?? ""}`
    );
  },

  /**
   * 获取应用的菜单、api数据 树形结构
   * @param {*} appkey
   * @returns
   */
  get_tree_funs(appkey) {
    return http.get(`/admin/v1/function/tree?appkey=${appkey}`);
  },

  /**
   * 获取接口列表tree格式
   * @param {*} appkey 
   * @returns 
   */
  get_apis(appkey){
    return http.get(`/admin/v1/function/apis?appkey=${appkey}`);
  }
};
